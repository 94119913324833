<template>
  <div id="sub-users">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />子账号
        </van-col>
        <van-col span="14" style="text-align: right" @click="onAddClick">
          <svg-icon :data_iconName="'add'" :className="'add-icon'" />
        </van-col>
      </van-row>
    </div>

    <div class="users-container" v-if="list.length > 0">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="padding-bottom: 2rem"
      >
        <div
          class="home-body-header"
          v-for="(user, index) in list"
          :key="index"
          @click.stop="onEditClick(index)"
        >
          <van-row>
            <van-col span="4">
              <van-image class="avatar" round :src="user.avatar" />
            </van-col>
            <van-col span="14">
              <div class="username">{{ user.username }}</div>
              <div class="mobile">{{ user.mobile }}</div>
            </van-col>
            <van-col span="6" @click.stop="onDeleteClick(user)">
              <div class="sitting" style="text-align: right">
                <svg-icon
                  :data_iconName="'delete'"
                  :className="'setting_icon'"
                />
              </div>
            </van-col>
          </van-row>
        </div>
      </van-pull-refresh>
    </div>
    <van-empty v-if="list.length==0&&loading==false" description="暂无数据" />
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
export default {
  name: "sub-user",
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onAddClick() {
      this.$router.push("/profile/add-subuser");
    },
    onEditClick(index) {
      this.$router.push({
        path: "/profile/edit-subuser",
        query: {
          index: index,
        },
      });
    },
    onDeleteClick(item) {
      const _self = this;
      Dialog.confirm({
        // title: "标题",
        message: "确定删除该子账号吗?",
      })
        .then(() => {
          // on confirm
          _self.delete_sub_user(item);
        })
        .catch(() => {});
    },
    async get_sub_users() {
      const res = await this.$apis.get_sub_users(this.query);
      if (res.next_page) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data]
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_sub_users();
    },
    async delete_sub_user(item) {
      const res = await this.$apis.delete_sub_user(item.id);
      if (res.status == 200) {
        Toast("操作成功");
        this.onRefresh();
      }
    },
  },
  created() {
    this.get_sub_users();
  },
};
</script>
<style scoped>
#sub-users {
  height: 100vh;
  /* background:#f3f3f3; */
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.add-icon {
  font-size: 0.6rem;
}
.home-body-header {
  padding: 0.2rem 0rem;
  position: relative;
}
.home-body-header .avatar {
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
.home-body-header .username {
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  color: #fff;
  font-size: 0.25rem;
  opacity: 0.74;
  margin-top: 4px;
}
.users-container {
  padding: 0.4rem;
  height: 86vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
</style>